import React from "react";
import Swiper from "react-id-swiper";
import "swiper/swiper.min.css";
import blogTwo1 from "../assets/images/blog-2-1.jpg";
import blogTwo2 from "../assets/images/blog-2-2.jpg";
import blogTwo3 from "../assets/images/blog-2-3.jpg";

const PriTable2 = () => {
  const params = {
    slidesPerView: 3,
    loop: false,
    speed: 1000,
    spaceBetween: 30,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },

    // Responsive breakpoints
    breakpoints: {
      1024: {
        slidesPerView: 3
      },
      768: {
        slidesPerView: 2
      },
      640: {
        slidesPerView: 1
      },
      320: {
        slidesPerView: 1
      }
    }
  };

  return (
    // GRADE I & II
    <section className="blog-two marginbottom">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
          ANNUAL ACTIVITY PLANNER FOR PARENTS ACADEMIC SESSION (2021-22)
          </h2>
        </div>
        <div className="blog-two__carousel">
          <Swiper {...params}>
            <div className="item">
              <div
                className="blog-two__single"
                style={{ backgroundImage: `url(${blogTwo1})` }}
              >
                <div className="blog-two__inner">
                  <a href="news-details.html" className="blog-two__date">
                    {/* <span>09</span> */}
                    JUNE
                  </a>
                  <div className="blog-two__meta">
                  <a href="#none">CHESS AND CARROM</a>
                    {/* <a href="#none">3 Comments</a> */}
                  </div>
                  <h3 className="blog-two__title">
                    <a href="#">
                    MALE & FEMALE
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="item">
              <div
                className="blog-two__single"
                style={{ backgroundImage: `url(${blogTwo2})` }}
              >
                <div className="blog-two__inner">
                  <a href="news-details.html" className="blog-two__date">
                    {/* <span>22</span> */}
                    JULY
                  </a>
                  <div className="blog-two__meta">
                    <a href="#none">MUSIC (VOCAL+INSTRUMENTAL)</a>
                    {/* <a href="#none"><strong style={{color:'black'}}>CATEGORY:</strong>MALE & FEMALE</a> */}
                  </div>
                  <h3 className="blog-two__title">
                    <a href="#">
                    MALE & FEMALE
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="item">
              <div
                className="blog-two__single"
                style={{ backgroundImage: `url(${blogTwo3})` }}
              >
                <div className="blog-two__inner">
                  <a href="news-details.html" className="blog-two__date">
                    {/* <span>30</span> */}
                    AUGUST
                  </a>
                  <div className="blog-two__meta">
                  <a href="#none">DRAWING</a>
                    {/* <a href="#none">3 Comments</a> */}
                  </div>
                  <h3 className="blog-two__title">
                    <a href="#">
                    MALE & FEMALE
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="item">
              <div
                className="blog-two__single"
                style={{ backgroundImage: `url(${blogTwo1})` }}
              >
                <div className="blog-two__inner">
                  <a href="news-details.html" className="blog-two__date">
                    {/* <span>19</span> */}
                    SEP
                  </a>
                  <div className="blog-two__meta">
                  <a href="#none">A-TABLE TENNIS</a>
                    {/* <a href="#none">3 Comments</a> */}
                  </div>
                  <h3 className="blog-two__title">
                    <a href="#">
                    MALE & FEMALE 
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="item">
              <div
                className="blog-two__single"
                style={{ backgroundImage: `url(${blogTwo1})` }}
              >
                <div className="blog-two__inner">
                  <a href="news-details.html" className="blog-two__date">
                    {/* <span>19</span> */}
                    SEP
                  </a>
                  <div className="blog-two__meta">
                  <a href="#none">B-BADMINTON</a>
                    {/* <a href="#none">3 Comments</a> */}
                  </div>
                  <h3 className="blog-two__title">
                    <a href="#">
                    MALE & FEMALE 
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="item">
              <div
                className="blog-two__single"
                style={{ backgroundImage: `url(${blogTwo2})` }}
              >
                <div className="blog-two__inner">
                  <a href="news-details.html" className="blog-two__date">
                    {/* <span>21</span> */}
                    OCT
                  </a>
                  <div className="blog-two__meta">
                  <a href="#none">A- VOLLEY BALL</a>
                    {/* <a href="#none">3 Comments</a> */}
                  </div>
                  <h3 className="blog-two__title">
                    <a href="#">
                    MALE & FEMALE
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="item">
              <div
                className="blog-two__single"
                style={{ backgroundImage: `url(${blogTwo2})` }}
              >
                <div className="blog-two__inner">
                  <a href="news-details.html" className="blog-two__date">
                    {/* <span>21</span> */}
                    OCT
                  </a>
                  <div className="blog-two__meta">
                  <a href="#none">B- DODGE BALL</a>
                    {/* <a href="#none">3 Comments</a> */}
                  </div>
                  <h3 className="blog-two__title">
                    <a href="#">
                    MALE & FEMALE
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="item">
              <div
                className="blog-two__single"
                style={{ backgroundImage: `url(${blogTwo3})` }}
              >
                <div className="blog-two__inner">
                  <a href="news-details.html" className="blog-two__date">
                    {/* <span>24</span> */}
                    NOV
                  </a>
                  <div className="blog-two__meta">
                  <a href="#none">A-CRICKET</a>
                    {/* <a href="#none">3 Comments</a> */}
                  </div>
                  <h3 className="blog-two__title">
                    <a href="#">
                    MALE & FEMALE
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="item">
              <div
                className="blog-two__single"
                style={{ backgroundImage: `url(${blogTwo3})` }}
              >
                <div className="blog-two__inner">
                  <a href="news-details.html" className="blog-two__date">
                    {/* <span>24</span> */}
                    NOV
                  </a>
                  <div className="blog-two__meta">
                  <a href="#none">B-ARTS AND CRAFT</a>
                    {/* <a href="#none">3 Comments</a> */}
                  </div>
                  <h3 className="blog-two__title">
                    <a href="#">
                    MALE & FEMALE
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="item">
              <div
                className="blog-two__single"
                style={{ backgroundImage: `url(${blogTwo3})` }}
              >
                <div className="blog-two__inner">
                  <a href="news-details.html" className="blog-two__date">
                    {/* <span>24</span> */}
                    JAN
                  </a>
                  <div className="blog-two__meta">
                  <a href="#none">A-KABADDI </a>
                    {/* <a href="#none">3 Comments</a> */}
                  </div>
                  <h3 className="blog-two__title">
                    <a href="#">
                    MALE & FEMALE
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="item">
              <div
                className="blog-two__single"
                style={{ backgroundImage: `url(${blogTwo3})` }}
              >
                <div className="blog-two__inner">
                  <a href="news-details.html" className="blog-two__date">
                    {/* <span>24</span> */}
                    JAN
                  </a>
                  <div className="blog-two__meta">
                  <a href="#none">B-DANCE(INDIAN/WESTERN)</a>
                    {/* <a href="#none">3 Comments</a> */}
                  </div>
                  <h3 className="blog-two__title">
                    <a href="#">
                    MALE & FEMALE
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="item">
              <div
                className="blog-two__single"
                style={{ backgroundImage: `url(${blogTwo3})` }}
              >
                <div className="blog-two__inner">
                  <a href="news-details.html" className="blog-two__date">
                    {/* <span>24</span> */}
                    FEB
                  </a>
                  <div className="blog-two__meta">
                  <a href="#none">QUIZ COMPETITION</a>
                    {/* <a href="#none">3 Comments</a> */}
                  </div>
                  <h3 className="blog-two__title">
                    <a href="#">
                    MALE & FEMALE
                    </a>
                  </h3>
                </div>
              </div>
            </div>
          </Swiper>
        </div>
      </div>
    </section>
    
  );
};
export default PriTable2;
