import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import event from "../components/event.js";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import http from '../_api/http'
const localizer = momentLocalizer(moment);
const ParCalender = () => {  
        
    const [events, setEvents] = useState([]);
    const now = new Date();
    
    const [Celebration, SetCelebration] = useState([])
    const PUBLIC_URL = process.env.REACT_APP_APIURL
     
    useEffect(() => {
        // alert(process.env.REACT_APP_APIURL);
        http.get('api/celebrationparent/ApiCelebrationParentList').then(res => {
          //  console.log(res);
          // debugger
          if (res.data.status === 200) {
            // console.log(res.data.status);
            // SetCelebration(res.data.celebration)
            let parseEvent = res.data.celebrationparent.map((m, index) => ({
              id: index,
              title: m.event_name,
              start: m.event_date,
              end: m.event_date,
              resourceId: m.id
            }))
            setEvents(parseEvent)
    
            // console.log(res.data.requirements);
            // setLoading(false);
          }
        })
      }, [])
   
    return (
     <div>
      <section className="course-one__top-title home-one">
        <div className="container">
          <div className="block-title mb-0">
            <h2 className="block-title__title">
            Parents Academic Session(2021-22)
            </h2>
          </div>
        </div>
        <div className="course-one__top-title__curve"></div>
      </section>

      <section className="course-one course-one__teacher-details home-one">
      <div className="pl-5 pr-5" style={{ height: '500pt' }}>
          <Calendar
            events={events}
            startAccessor="start"
            endAccessor="end"
            defaultDate={moment().toDate()}
            localizer={localizer}
          />
        </div>
      </section>
    </div>
    );
  }
   

  

export default ParCalender;
